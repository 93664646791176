<script setup>
import store from "@/store";
import {computed,ref} from "vue";
import router from "@/router";
import {api} from "@/utils";
import {userStateApi} from "@/utils/apis";
const infoDialogRef = ref(null);
const topMenu = computed(()=>store.getters.rules.filter(d=>d.parent === 0));
const subMenu = computed(()=>
    {
      var d =  store.getters.rules.filter(d=>{
        console.log(d.rule_path)
        return d.parent !== 0 &&  d.rule_path.startsWith(currentTop.value[0]) && currentTop.value != "/";
      })
      return d;
    }
);
const loginOut = ()=>{
  store.commit("loginOut");
}
const editPassword = ()=>{
  infoDialogRef.value.openDialog({});
}
const edit_password = ({form,cb})=>{
  api(userStateApi.editPassword)(form).then(()=>{
    cb(true);
    store.commit("loginOut");
  }).catch(()=>{
    cb(false);
  });
}
const currentTop = computed(()=>
{
  const path = router.currentRoute.value.path;
  const paths = path.split("/");
  return [`/${paths[1] ?? ""}`];
});
const currentSub = computed(()=>
{
  const path = router.currentRoute.value.path;
  const paths = path.split("/");
  return [`/${paths[1] ?? ""}/${paths[2] ??""}`];
});
const pageChange = (e)=> router.push(e)
</script>
<template>
  <info-dialog title="修改密码" ref="infoDialogRef" @confirm="edit_password">
    <template #default="{form}">
      <a-form-item label="原密码" field="password" :rules="[{required:true,message:'原密码必须输入'}]">
        <a-input-password v-model="form.password" placeholder="请输入原密码" />
      </a-form-item>
      <a-form-item label="新密码" field="new_password"   :rules="[{required:true,message:'新密码必须输入'}]">
        <a-input-password v-model="form.new_password" placeholder="请输入新密码" />
      </a-form-item>
      <a-form-item label="重复密码" field="re_password"   :rules="[{required:true,message:'重复原密码必须输入'}]">
        <a-input-password v-model="form.re_password" placeholder="请重复输入新密码" />
      </a-form-item>
    </template>
  </info-dialog>
  <a-layout class="z-layout">
    <a-layout-header class="z-layout-header">
      <div class="logo">在线抽奖</div>
      <a-menu @menu-item-click="pageChange" :selected-keys="currentTop" mode="horizontal">
        <a-menu-item v-for="(item) in topMenu" :key="item.rule_path">{{item.rule_name}}</a-menu-item>
      </a-menu>
      <div class="right">
        <a-space>
          <a-button type="text" @click="editPassword">修改密码</a-button>
          <a-button type="text" @click="loginOut">退出登录</a-button>
        </a-space>
      </div>
    </a-layout-header>
    <a-layout>
      <a-layout-sider v-if="subMenu.length > 0" class="z-layout-sider">
        <a-menu @menu-item-click="pageChange" :selected-keys="currentSub">
          <a-menu-item v-for="(item) in subMenu" :key="item.rule_path">{{item.rule_name}}</a-menu-item>
        </a-menu>
      </a-layout-sider>
      <a-layout-content class="z-layout-content">
          <div class="content">
            <slot />
          </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<style scoped lang="scss">
  .z-layout{
    --sider-width:140px;
    .z-layout-header{
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo{
        text-align: center;
        width:var(--sider-width);
      }
      .arco-menu{
        flex: 1;
      }
    }
    .z-layout-content{
      position: relative;
      background-color: #eeeeee;
      >.content{
        position: absolute;
        left: 5px;
        right: 0;
        top: 5px;
        bottom: 0;
        background-color: #fff;
        padding: 5px;
      }
    }
    .z-layout-sider{
      width:var(--sider-width)!important;
    }
  }
</style>