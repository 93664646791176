import axios from "axios";
import { Notification }from '@arco-design/web-vue'
let baseUrl = "/index.php/admin";
import store from '@/store'
if (process.env.NODE_ENV  === 'development'){
  baseUrl = "/api/index.php/admin";
}
export const base_url = baseUrl;
const ajax = axios.create({
  baseURL:baseUrl,
  timeout:10000
})
ajax.interceptors.request.use(
config=>{
    config.headers["token"] = store.getters.token;
    return config;
},
e=>
{
  return Promise.reject(e.message);
});
ajax.interceptors.response.use(
  response=>response.data,
  err=>{
    return new Promise((resolve,reject)=>{
      
      const response = err.response;
      if (response.status === 401){
        Notification.error({
          title:"登录失效",
          content:response.data.msg
        });
        store.commit("loginOut");
        reject(response.data.msg);
      }else if(response.status === 403){
        Notification.error({
          title:"权限",
          content:response.data.msg
        });
        reject(response.data.msg);
      }else{
        reject(response.status);
      }
    })
});
export const post =(url,data = {})=> ajax.post(url,data);
export const get =(url,param = {})=> ajax.get(url,{params:param});
// 直接返回文件
export const download = (url,param)=>ajax.get(url,{
  params:param,
  responseType:"blob",
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
export const api = (fn)=>{
  return (...args)=>{
    return new Promise((resolve,reject)=>{
      fn(...args).then(res=>{
        console.log(res);
        if (res.code == 1){
          if (res.msg){
            Notification.success({
              content:res.msg
            })
          }
          resolve(res.data);
        }else{
          Notification.error({
            content:res.msg
          });
          reject(res.msg);
        }
      }).catch(reject)
    });
  }
}
// 将字符串转为日期
export const checkDate = (str)=>{
  const date = new Date(str);
  if (date instanceof  Date && !isNaN(date.getTime())){
    return date;
  }
  return false;
}
// 加载在线的JS
export const import_js = (url,key,only = true)=>{
  let js;
   return new Promise((resolve, reject)=>{
     url = `${url}?t=${(new Date()).getTime()}`
     if (only){
        let has_js = document.getElementById(key);
        if (has_js){
          has_js.remove();
        }
     }
     js = document.createElement("script");
     js.src = url;
     js.onload = ()=>{
       resolve(window[key] ?? null);
     }
     js.error = ()=>{
       reject("加载失败");
     }
     document.head.appendChild(js);
   });
}