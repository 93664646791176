import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from '@arco-design/web-vue'
import custom from '@/components'
import "@arco-design/web-vue/dist/arco.css"
import "@/assets/style.scss"
import {userStateApi} from "@/utils/apis";
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
const app = createApp(App);
app.use(element).use(store).use(router).use(custom).use(ArcoVueIcon);
if (store.getters.isLogin)
{
  userStateApi.checkSession().then(res=>{
    store.commit("updateRules",res.data);
    app.mount('#app');
  }).catch(()=>{
    console.log("登录失效")
  })
}else{
  app.mount("#app");
}