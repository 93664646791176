import {post,api,get} from './index'
export const userStateApi = {
  login:data=>api(post)("/login/login",data),
  checkSession :()=>get("/index/check_session"),
  editPassword :data=>post("/index/edit_password",data)
}
export const appApi = {
  app:()=>api(get)("/app.app/index"),
  saveApp:data=>api(post)("/app.app/update",data),
  rule:()=>get("/app.rule/index"),
  updateRule:data=>post("/app.rule/update",data),
  deleteRule:id=>get("/app.rule/delete",{id:id})
}
export const userApi = {
  index:query=>api(get)("/user/index",query),
  updateUserStater:(query)=>get("/user/update_state",query)
}
export const activityApi = {
  index:query=>get("/activity.activity/index",query),
  info:id=>get("/activity.activity/info",{id:id}),
  update:data=>post("/activity.activity/update",data),
  delete:id=>post("/activity.activity/delete",{id:id})
}
export const saleApi = {
  saleIndex:query=>get("/sale.sale/index",query),
  updateSale:data=>post("/sale.sale/update",data),
  deleteSale:id=>get("/sale.sale/delete",{id:id}),
  shopIndex:query=>get("/sale.shop/index",query),
  updateShop:data=>post("/sale.shop/update",data),
  deleteShop:id=>get("/sale.shop/delete",{id:id}),
  productIndex:query=>get("/sale.product/index",query),
  updateProduct:data=>post("/sale.product/update",data),
  deleteProduct:id=>get("/sale.product/delete",{id:id}),
}
export const recordApi = {
  index:(query)=>get("/record.index/index",query),
}
export const statisticsApi = {
  shop:query=>get("/statistics.shop/index",query),
  sale:query=>get("/statistics.sale/index",query),
}