<script setup>
import {cloneDeep} from 'lodash'
import {defineProps} from 'vue';
import {Notification} from '@arco-design/web-vue'
const config = defineProps({
    checkedValue:{default(){return 1}},
    checkedLabel:{default(){return "启用"}},
    uncheckedValue:{default(){return 2}},
    uncheckedLabel:{default(){return "禁用"}},
    query:{default(){return {}}},
    valueKey:{default(){return "key"}},
    api:{
      default(){
        return null
      }
    }
});
const  beforeChange =  async (newValue)=>{
  let data = cloneDeep(config.query);

  if(typeof data != "object"){
    data = {};
  }
  data[config.valueKey] = newValue;
  try{
    const v = await config.api(data);
    if(v.code === 1){
      Notification.success({
        content:v.msg
      })
      return true;
    }else{
      Notification.error({
        content:v.msg
      });
      return false;
    }
  }catch(e){
    
    return false;
  }
}
</script>

<template>
<a-switch 
        :before-change="beforeChange"
        :checked-value="config.checkedValue"
          :checked-text="checkedLabel"
          :unchecked-text="uncheckedLabel"
          :unchecked-value="uncheckedValue">


</a-switch>
</template>

<style scoped lang="scss">

</style>