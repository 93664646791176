<script setup>
import {defineProps} from 'vue';
const props = defineProps({
  title:{default(){return ""}},
  hiddenContent:{default(){return false},type:Boolean}
})
</script>

<template>
<div class="z-app-bar">
  <div class="header">
    <div class="title">{{props.title}}</div>
    <div class="action">
      <slot name="extra"></slot>
    </div>
  </div>
  <div class="container" v-if="!hiddenContent">
    <slot />
  </div>
</div>
</template>
<style scoped lang="scss">
.z-app-bar{
  >.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 22px;
    .title{
      vertical-align: top;
      padding: 10px 7px;
      &:before{
        content: " .";
        color: #08f;
        width:5px;
        background-color: #08f;
        height:15px;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  >.container{
    padding: 22px;
  }
}
</style>