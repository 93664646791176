import FormBlock from "@/components/src/FormBlock.vue";
import PictureUpload from "@/components/src/PictureUpload.vue";
import ZDataLayout from "@/components/src/ZDataLayout.vue";
import SwitchLoading from "@/components/src/SwitchLoading.vue";
import DeleteItem from "@/components/src/DeleteItem.vue";
import InfoDialog from "@/components/src/InfoDialog.vue";
const components = {FormBlock,PictureUpload,ZDataLayout,SwitchLoading,DeleteItem,InfoDialog};
export default {
  install(Vue){
    Object.keys(components).forEach(k=>{
      Vue.component(k,components[k]);
    })
  }
}