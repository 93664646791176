export default [
  /* 系统配置 */
  {name:"wechat",path:"/app",redirect: "/app/wechat"},
  {name:"wechat_index",path:"/app/wechat",component:()=>import("@/views/app/WechatInfo.vue")},
  {name:"system_index",path:"/app/rule",component:()=>import("@/views/system/RuleIndex.vue")},
  
  /* 用户管理 */
  {name:"user",path:"/user",redirect: "/user/index"},
  {name:"user_index",path:"/user/index",component:()=>import("@/views/user/UserIndex.vue")},

  /* 活动管理 */
  {name:"activity",path:"/activity",redirect: "/activity/index"},
  {name:"activity_index",path:"/activity/index",component:()=>import("@/views/activity/IndexPage.vue")},
  {name:"activity_info",path:"/activity/info",component:()=>import("@/views/activity/ActivityInfo.vue")},

  /* 商品管理 */
  {name:"sale",path:"/sale",redirect: "/sale/index"},
  {name:"sale_index",path:"/sale/index",component:()=>import("@/views/sale/SaleIndex.vue")},
  {name:"sale_shop",path:"/sale/shop",component:()=>import("@/views/sale/ShopIndex.vue")},
  {name:"sale_product",path:"/sale/product",component:()=>import("@/views/sale/ProductIndex.vue")},
  /* 活动记录 */
  {name:"record_index",path:"/record",component:()=>import("@/views/record/RecordIndex.vue")},
    /* 统计*/
  {name:"statistics",path:"/statistics",redirect: "/statistics/shop"},
  {name:"statistics_shop",path:"/statistics/shop",component:()=>import("@/views/statistics/ShopIndex.vue")},
  {name:"statistics_sale",path:"/statistics/sale",component:()=>import("@/views/statistics/SaleIndex.vue")},
]