<template>
  <layout-component v-if="isLogin">
    <router-view />
  </layout-component>
  <login-component v-else />
</template>
<script setup>
import LoginComponent from "@/components/LoginComponent.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import {computed} from "vue";
import store from "@/store";
const isLogin = computed(()=>store.getters.isLogin)
</script>