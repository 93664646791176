<script setup>
import {defineProps, onMounted, ref,defineEmits} from 'vue';
const DataContainerRef = ref(null);
const emit = defineEmits(["page"]);
const config = defineProps({
  columns:{default:()=>[]},
  total:{default:()=>0},
  pageSize:{default:()=>20},
  dataList:{default:()=>[]},
  loading:{default:()=>false},
  rowKey:{default:()=>""}
});
import {debounce} from 'lodash'
const containerSize = ref({
  width:200,
  height:200,
})
const updateSize  = ()=>{
  const dom = DataContainerRef.value;
  if (dom){
    const {offsetWidth,offsetHeight} = dom;
    containerSize.value.width = offsetWidth;
    containerSize.value.height = offsetHeight;
  }
}
onMounted(()=>{
  window.addEventListener("resize",debounce(updateSize,120));
  updateSize();
})
const changePage =(e)=>{
  emit("page",e)
}
</script>
<template>
<div class="z-data-layout">
  <div class="z-header">
    <div class="left">
      <slot name="left"></slot>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
  <div class="z-container" ref="DataContainerRef">
    <div class="z-content">
      <slot :size="containerSize" >
        <a-table 
        :row-key="rowKey"
        :loading="config.loading" 
        :pagination="false"
        :data="config.dataList" size="small" 
        :scroll="{maxHeight:containerSize.height+'px',x:'100%'}" 
        :columns="config.columns">
     
        </a-table>
      </slot>
    </div>
  </div>
  <div class="footer">
    <div class="pagination"  v-if="total >0">
      <a-pagination show-total :page-size="config.pageSize" :total="total" @update:current="changePage"></a-pagination>
    </div>
  </div>
</div>
</template>
<style scoped lang="scss">
.z-data-layout{
  display: flex;
  flex-direction: column;
  height:100%;
  >.z-header{
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left{
      flex:1;
    }
  }
  >.z-container{
    flex:1;
    position: relative;
    overflow: hidden;
    >.z-content{
      position: absolute;
      left: 10px;
      right: 10px;
      top:10px;
      bottom: 10px;
    }
  }
  >.footer{
    padding: 7px 10px;
  }
}

</style>