<template>
<div class="login-page">
  <a-card title="用户登录">
    <a-form auto-label-width>
      <a-form-item label="用户名">
        <a-input v-model="loginData.username" />
      </a-form-item>
      <a-form-item label="密码">
        <a-input-password v-model="loginData.password" />
      </a-form-item>
      <a-form-item>
        <a-button @click="loginUser" type="primary">{{ btnText }}</a-button>
      </a-form-item>
    </a-form>
  </a-card>
</div>
</template>
<script setup>
import store from '@/store'
import {computed, ref} from 'vue';
import {userStateApi} from "@/utils/apis";
const loginData = ref({});
const loading = ref(false);
const btnText = computed(()=>{
  return loading.value ? "登陆中" : '立即登录';
});
const loginUser = ()=>{
  loading.value = true;
    userStateApi.login(loginData.value).then(res=>{
      store.commit("login",res);
    }).catch(()=>{

    }).finally(()=>{
      loading.value = false;
  })
}
</script>
<style scoped>
.login-page{
  height:100%;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>