import { createStore } from 'vuex'
import persistedstate from 'vuex-persistedstate'
import cookie from 'js-cookie'
export default createStore({
  state: {
    username:"",
    token:"",
    rules:[],
  },
  getters: {
    isLogin:state=>{
      return !!(state.token)
    },
    token:state=>state.token,
    rules:state=>state.rules
  },
  mutations: {
    login:(state,value)=>{
      state.token = value.token;
      state.username = value.username;
      state.rules    = value.rules;
    },
    loginOut:(state)=>{
      state.token = "";
      state.username = "";
    },
    updateRules:(state,rules)=>{
      state.rules = rules;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins:[
    persistedstate({
      storage:{
        getItem:key=>cookie.get(key),
        setItem:(key,value)=>cookie.set(key,value),
        removeItem:key=>cookie.remove(key)
      },
      reducer(state){
        return{
          username:state.username,
          token:state.token,
        }
      }
    })
  ]
})
