<template>
<a-modal :width="config.width"
         :title="config.title"
         @ok="confirm"
         @cancel="cancel" :ok-loading="isLoading" :visible="isShow">
    <a-form @submit-success="onSubmit" :model="formData" auto-label-width>
        <slot :form="formData" />
      <button type="submit" style="display: none" ref="submitButtonRef"></button>
    </a-form>
</a-modal>
</template>
<script setup lang="jsx">
import {ref,defineExpose,defineEmits,defineProps} from 'vue'
import {cloneDeep} from "lodash";
const config = defineProps({
  title:{default:()=>""},
  width:{default:()=>"700px"}
})
const isShow = ref(false);
const isLoading  =ref(false);
const formData = ref({});
const submitButtonRef = ref(null);
const emit  = defineEmits(["confirm"])
const openDialog = (e)=>{
    formData.value = cloneDeep(e);
    isShow.value = true;
}
const confirm = ()=>{
  submitButtonRef.value.click();
}
const onSubmit = (e)=>{
  console.log(e)
  isLoading.value = true;
  emit("confirm",{
    form:formData.value,
    cb:closeCallback
  });
}
const cancel = ()=>{
    isShow.value = false;
}
const closeCallback = (e)=>{
    isShow.value = !e;
    isLoading.value = false;
}
defineExpose({
    openDialog
});
</script>