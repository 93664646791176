<script setup>
import {computed, defineProps,ref,defineEmits} from 'vue'
const emit = defineEmits(["delete"])
import {api} from "@/utils";
const config = defineProps({
  content:{default:()=>""},
  title:{default:()=>""},
  api:{default:()=>null},
  id:{default:()=>0},
});
const loading = ref(false);
const click = computed(()=>{
  return config.api === null ||  typeof config.id != 'number' ||  config.id <= 0
});
const confirm =()=>{
  loading.value = true;
  api(config.api)(config.id).then(()=>{
    loading.value = false;
    emit("delete");
  }).catch(()=>{})
}
</script>
<template>
  <a-popconfirm :content="config.content" :ok-loading="loading" :disabled="click" @ok="confirm" >
    <slot name="content">
      <a-link status="danger" >
        <slot></slot>
      </a-link>
    </slot>
  </a-popconfirm>

</template>

<style scoped lang="scss">

</style>