<script setup>
import {computed, defineProps, ref,defineEmits} from "vue";
import {Notification} from "@arco-design/web-vue";
import {base_url} from "@/utils";
import store from "@/store";
const emit = defineEmits(["update:file"]);
const config = defineProps({
  tips:{default(){return "选择图片"}},
  file:{default(){return {}}}
});
const uploadingFile = ref({});
const file = computed({
  get(){
    if (config.file &&config.file.url) return config.file.url;
    return "";
  }
});
const changeFile = (_,file)=>{
  uploadingFile.value = file;
}
const uploadSuccess = (e)=>{
  const response = e.response ;
  if (response.code === 1){
    emit("update:file",response.data);
  }else{
    Notification.error({
      title:"文件上传失败",
      content:response.msg ?? "未知原因"
    }) ;
  }
}
const uploadFail = (e)=>{
  const response = e.response;
  if (response.code === 401){
    Notification.error({
      title:"登录失效",
      content:"请重新登录"
    });
    store.commit('loginOut');
  }else if (response.code === 403)
  {
    Notification.error({
      title:"权限受限",
      content:"您没有权限上传图片"
    });
  }else{
    Notification.error({
      title:"图片上传失败",
      content:response.msg ??  "未知原因"
    })
  }
}
/// 上传信息
const actionUrl = computed(()=>{
  return `${base_url}/index/upload_picture`;
})
const header = computed(()=>{
  return {
    token:store.getters.token
  }
})
</script>

<template>
<div class="z-upload-picture">
  <a-upload
      name="picture"
      :action="actionUrl"
      :headers="header"
      @success="uploadSuccess"
      @error="uploadFail"
      @progress="progressUpdate"
      @change="changeFile"
      accept=".png,.jpg,.gif"
      :show-file-list="false">
    <template #upload-button>
      <div class="z-upload-container">
        <a-progress
            v-if="uploadingFile.status==='uploading' && uploadingFile.percent < 100"
            :style="{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translateX(-50%) translateY(-50%)',
        }" :percent="uploadingFile.percent" type="circle" />
        <img class="uploaded-picture" v-if="file" width="120" height="120px" :src="file" alt="图片" />
        <div class="upload-btn" v-else>
          <div class="icon"><icon-upload size="20" /></div>
          <div class="tips">{{ config.tips }}</div>
        </div>
      </div>
    </template>
  </a-upload>
</div>
</template>

<style scoped lang="scss">
.z-upload-picture,.arco-upload,  .upload-btn,.uploaded-picture{
  width:120px;
  height:120px;
}
.z-upload-container{
  position: relative;
}
.z-upload-picture{
  background-color: #eee;
  cursor: pointer;
  .upload-btn{
    color: #3a3a3a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >.icon{
      margin-bottom: 10px;
    }
  }
}
</style>